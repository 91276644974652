.from_control {
  margin-top: 0.4rem;
  width: 250px;
  padding: 0.4rem 0.8rem;
  outline: none;
  background-color: rgb(249, 249, 249);
  // border: 1px solid rgb(6, 6, 6);
  border-radius: 6px;

  border: 1px solid black;
}
.from_control_invalid {
  border: 2px solid red !important;
  // border:1px solid red !important;
}
.control_from {
  border-radius: 5px;
  padding: 0.2rem 0.8rem;
  margin: 0.2rem 0 0 0;
  outline: none !important;
  border: 2px solid gray;

  box-shadow: none;
  &:focus {
    outline: none;
    box-shadow: none;
    border-radius: 5px;
    padding: 0.2rem 0.8rem;
    margin: 0.2rem 0 0 0;
    outline: none !important;
    border: 2px solid gray;
  }
  &:active {
    border-radius: 5px;
    padding: 0.2rem 0.8rem;
    margin: 0.2rem 0 0 0;
    outline: none !important;
    border: 2px solid gray;
    outline: none !important;
    border: none !important;
  }
}
.control_from_invalid {
  border-radius: 5px;
  padding: 0.2rem 0.8rem;
  margin: 0.2rem 0 0 0;
  outline: none !important;
  border: 2px solid red;
}
.disabled {
  background-color: #f7f6f6;
}
.form_heading {
  font-size: 1.4rem;
  font-weight: bold;
}
.form_label {
  font-weight: bold;
}

.yes_no_btn {
  border-radius: 6px;
  cursor: pointer;
  padding: 0.6rem;
  border: 1px solid rgb(139, 139, 139);
  text-align: center;
}
// .yes_Btn {
//   background-color: #dcfce7;
//   color: #22c55e;
// }
.yes_Btn_blue {
  background-color: #1c60ff;
  color: #ffffff;
  font-weight: bold;
  border: 1px solid #0051ff5a;
}
.no_Btn_white {
  background-color: #ffffff;
  color: #000000;
  font-weight: bold;
}
.no_Btn {
  background-color: #ffe4e6;
  color: #e11d48;
  border: 1px solid #ff00375a;
  font-weight: bold;
}

.yes_Btn {
  background-color: #dcfce7;
  color: #22c55e;
  border: 1px solid #3a985daf;
  font-weight: bold;
}
.back {
  background-color: none;
}

.tdl_test {
  .ant-select-selector {
    border: 1px solid #dde0e5 !important;
  }
  .ant-select-selection-search-input {
    height: 2.4rem;
  }
}

.textHeadline{
  border: 1px solid grey;
  text-align:center;
  font-size:18px;
  font-weight:bold;
  background-color:gray;
  color:#fff
}

