/* src/App.css */
*{
  margin: 0;
  padding: 0;
 box-sizing: border-box;
}

.App {
    text-align: center;
  }
  
  // .App-header {
  //   background-color: #282c34;
  //   min-height: 100vh;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   font-size: calc(10px + 2vmin);
  //   color: white;
  // }
  
  .formMain {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    // display: flex;
    height: 70vh;
    margin-top: 1rem;

  }
  label{
    text-align: start;
    line-height: 2;
  }
  input {
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 0;
  }
  .infsys{
   display: flex;
   position: relative;
   height: 100px;
  }
  .btn_div {
    padding: 10px;
    display: flex;
    
    
  }
  .conf_pass{
    margin-left: 10em;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .manageMain {
      text-align: start;
  }