/* CustomDropdown.css */
.dropdown1 {
    position: relative;
    display: inline-block;
    top: 2px;
}

.dropdown-toggle1 {
    background-color: #3F4D67;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    /* padding: 10px 20px; */
    border: none;
    cursor: pointer;
    font-size: 30px;
    color: #EEF3FB;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown-toggle1:hover {
    /* background-color: #0056b3; */
}

.dropdown-menu1 {
    display: block;
    /* position: absolute; */
    background-color: #EEF3FB;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); */
    /* z-index: 1; */
    /* margin-top: 11px; */
    border-radius: 30px;
    overflow: hidden;
    border:1px solid #3F4D67;
    height:30px;
    /* position:relative */
    padding:0px 10px;
}

.container-triangle {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 14px solid #EEF3FB;
    top: 29px;
    z-index: 9999999;
    left: 14px;
}

.dropdown-menu1 a {
    text-decoration: none;
    cursor: pointer;
    font-weight: 501;
    color:#3F4D67;
    display:flex;
    /* justify-content: center; */
    align-items: center;
    gap:10px;
}

.dropdown-item1 {
    /* padding: 10px 15px; */
    text-decoration: none;
    color: black;
    display: block;
}

.dropdown-item1:hover {
    /* background-color: #f1f1f1; */
    color: 'black'
}