.cursors {
  cursor: pointer;
}
.headName {
  color: #2563eb;
}
.ag-input-field-input {
  background-color: #e5e5e6;
  border: 1px solid rgb(220, 220, 220) !important;
  border-radius: 4px !important;
}
.ag-root-wrapper p {
  line-height: 18px !important;
  padding-top: 10px !important;
}
.ag-header-viewport {
  background: #f9fafb !important;
}

.view_btn {
  font-size: 1.2rem;
}
.cell_wrap_wrap {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: min(var(--ag-line-height, 30px), 30px) !important;
}
.icon_action {
  font-size: 1.8rem;
}
.ag-cell-wrap-text {
  white-space: normal;
  word-break: normal !important;
}
.ag-cell {
  border-right: 1px solid rgb(222, 221, 221) !important;
}

/* Make the medication cell content scrollable */
// .ag-grid .medication-cell {
//   max-height: 100px; /* Set the maximum height for the cell */
//   overflow-y: auto; /* Enable vertical scrollbar when content overflows */
//   white-space: normal; /* Allow line breaks */
//   padding-right: 5px; /* Optional: add some space for scrollbar */
// }

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
