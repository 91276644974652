.custom_input_container {
  position: relative;

  .custom_time {
    position: absolute;
    left: 0;
    z-index: 1000;
  }
  .custom_input_clock {
    position: absolute;
    top: 50%;
    left: 90%;
    transform: translate(-50%, -50%);
  }
}
.selTime {
  background: #04a9f5;
  color: #fff !important;
}
